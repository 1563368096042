import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Title, Subtitle } from "./Text"
import { format } from "date-fns"

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`

const AuthorProfileImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: ${props => props.theme.margins.lg};
  overflow: hidden;
`

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Author = ({ author: { name, profileImage }, createdAt }) => (
  <AuthorContainer>
    <AuthorProfileImageContainer>
      <Img fluid={profileImage.fluid} />
    </AuthorProfileImageContainer>

    <AuthorInfo>
      <Title size="sm" color="grey900" black>
        {name}
      </Title>
      <Subtitle size="xs" color="grey500">
        {format(createdAt, "D MMM, YYYY")}
      </Subtitle>
    </AuthorInfo>
  </AuthorContainer>
)

export default Author
