import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Metatags from "../components/base/Metatags"
import { BackgroundImageContainer } from "../course/Components"
import { FluidContainer, ContainerSmall } from "../components/base/Container"
import { MainTitle } from "../components/base/Text"
import { MarkdownPost } from "../components/base/markdown/Markdown"
import Author from "../components/base/Author"

const PostWrapper = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
`

const AuthorContainer = styled.div`
  margin-bottom: ${props => props.theme.margins.xl};
`

// Tiny

const PostTemplate = ({ data, location }) => {
  const {
    title,
    excerpt,
    slug,
    backgroundImage,
    author,
    body: { body },
    isBlogPost,
    createdAt,
  } = data.contentfulPost

  return (
    <Layout pathname={location.pathname} light>
      <Metatags metaTitle={title} metaDescription={excerpt} slug={slug} />

      {backgroundImage && (
        <BackgroundImageContainer>
          <Img fluid={backgroundImage.fluid} />
        </BackgroundImageContainer>
      )}

      <FluidContainer>
        <ContainerSmall>
          <PostWrapper>
            <MainTitle size="xxl" color="grey900" margin="md" black>
              {title}
            </MainTitle>
            {isBlogPost && (
              <AuthorContainer>
                <Author author={author} createdAt={createdAt} />
              </AuthorContainer>
            )}

            <MarkdownPost text={body} />
          </PostWrapper>
        </ContainerSmall>
      </FluidContainer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      isBlogPost
      title
      slug
      excerpt
      body {
        body
      }
      author {
        name
        profileImage {
          title
          description
          fluid(maxWidth: 40) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      backgroundImage {
        title
        description
        fluid(maxWidth: 1440) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      createdAt
    }
  }
`

export default PostTemplate
